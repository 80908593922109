<template>
  <div class="pageCenter">
    <div class="header">
      <div class="logo">
        <img src="../assets/image/首页/LOGO.png" />
      </div>

      <div class="header_menu">
        <el-menu
          class="sidebar-el-menu"
          :default-active="onRoutes"
          mode="horizontal"
          @select="handleSelect"
          router
        >
          <el-menu-item index="homePage"> 首页</el-menu-item>
          <el-menu-item index="productService"> 产品服务</el-menu-item>
          <el-menu-item index="newsConsult"> 新闻资讯</el-menu-item>
          <el-menu-item index="aboutUs"> 关于我们</el-menu-item>
          <div class="line"></div>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { index: 'homePage', title: '首页' },
        { index: 'productService', title: '产品服务' },
        { index: 'newsConsult', title: '新闻资讯' },
        { index: 'aboutUs', title: '关于我们' }
      ]
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(123)
      // console.log(key, keyPath)
    }
  },
  computed: {
    // onRoutes() {
    //   return this.$route.path.replace('/', '')
    // }
  }
}
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 80px;
  /* 栅格布局 */
  display: grid;
  padding: 0 40px;
  /* 两列，一列占三分之一，一列占三分之二 */
  grid-template-columns: 1fr 2fr;
  /* 垂直居中 */
  align-items: center;
  /* 用于定位导航菜单 */
  position: relative;
  z-index: 200;
}
.header_menu {
  justify-self: end;
  .el-menu {
    border-bottom: 0 !important;
  }
}

@media (max-width: 1100px) {
  /* 导航设置为不可见，点击折叠按钮显示全屏导航 */
  .header_menu {
    display: none;
  }
}
</style>
