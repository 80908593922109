import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

export const routes = [
  { path: '/', redirect: '/homePage' },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/homePage',
        name: 'homePage',
        meta: {
          title: '首页'
        },
        component: () => import('../views/homePage.vue')
      },
      {
        path: '/productService',
        name: 'productService',
        redirect: { name: 'bigData' },
        meta: {
          title: '产品服务'
        },
        component: () => import('../views/productService.vue'),
        children: [
          {
            path: '/chargingPile',
            name: 'chargingPile',
            component: () => import('../views/productServiceFile/chargingPile.vue')
          },
          {
            path: '/monitoringPlatform',
            name: 'monitoringPlatform',
            component: () =>
              import('../views/productServiceFile/monitoringPlatform.vue')
          },
          {
            path: '/securityPlatform',
            name: 'securityPlatform',
            component: () =>
              import('../views/productServiceFile/securityPlatform.vue')
          },
          {
            path: '/travelApp',
            name: 'travelApp',
            component: () => import('../views/productServiceFile/travelApp.vue')
          },
          {
            path: '/bigData',
            name: 'bigData',
            component: () => import('../views/productServiceFile/bigData.vue')
          }
        ]
      },
      {
        path: '/newsConsult',
        name: 'newsConsult',
        meta: {
          title: '新闻资讯'
        },
        component: () => import('../views/newsConsult.vue')
      },
      {
        path: '/companyNewsOne',
        name: 'companyNewsOne',
        component: () => import('../views/newsConsultFile/companyNewsOne.vue')
      },
      {
        path: '/companyNewsTwo',
        name: 'companyNewsTwo',
        component: () => import('../views/newsConsultFile/companyNewsTwo.vue')
      },
      {
        path: '/companyNewsThree',
        name: 'companyNewsThree',
        component: () => import('../views/newsConsultFile/companyNewsThree.vue')
      },
      {
        path: '/industryNewsOne',
        name: 'industryNewsOne',
        component: () => import('../views/newsConsultFile/industryNewsOne.vue')
      },
      {
        path: '/industryNewsTwo',
        name: 'industryNewsTwo',
        component: () => import('../views/newsConsultFile/industryNewsTwo.vue')
      },
      {
        path: '/industryNewsThree',
        name: 'industryNewsThree',
        component: () => import('../views/newsConsultFile/industryNewsThree.vue')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: {
          title: '关于我们'
        },
        component: () => import('../views/aboutUs.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
