<template>
  <div class="pageCenter">
    <div class="footer">
      <div class="footer_logo">
        <img src="../assets/image/首页/底部_logo.png" alt="" />
      </div>
      <div class="footer_phone">
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-col :span="4"><span>电话：027-87897001</span></el-col>
          <el-col :span="4"><span>公司邮件：wuhanxdkj@163.com</span></el-col>
          <el-col :span="4"><span>服务QQ：40540933</span></el-col>
          <el-col :span="6.5"
            ><span
              >地址：湖北省武汉市武昌区楚河汉街-汉街国际总部A座1607</span
            ></el-col
          >
        </el-row>
      </div>
      <div class="footer_copyright">
        <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">鄂ICP备2021010467号-1</a></p>
        <span>版权所有：</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
          >©2021-至今 Macromedia, Inc. All rights reserved.</span
        >
      </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 300px;
  color: #fff;
  background-color: #3c3f52;
  padding-top: 95px;
  position: relative;
  .footer_logo {
    position: absolute;
    top: 70px;
    left: 670px;
    width: 580px;
    height: 60px;
    text-align: center;
    z-index: 10;
    background: #3c3f52;
  }
  .footer_phone {
    width: 1595px;
    height: 150px;
    line-height: 150px;
    margin-left: 165px;
    border-top: #fff 1px solid;
  }
  .grid-content {
    height: 4px;
    background-color: #fff;
  }
  .footer_copyright {
    width: 100%;
    text-align: center;
  }
}
</style>
