<template>
  <div>
    <div class="header_menu">
      <v-header />
    </div>
    <div class="content">
      <router-view/>
    </div>
    <v-footer />
  </div>
</template>

<script>
import vHeader from '@/components/Header.vue'
import vFooter from '@/components/Footer.vue'
export default {
  components: {
    vHeader,
    vFooter
  }
}
</script>
<style lang="less" scoped>
  .header_menu{
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 10000;
  }
  .content{
    margin-top: 80px;
  }
</style>
